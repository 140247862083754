import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_r = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - R'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>R</h2>
     <LatQuotesIntro />
    
     <p><b>Radix omnium malorum est cupiditas</b> - The love of money is the root of all evil. Avarice is the problem, money itself is not evil</p>
<p><b>Rara avis</b> - A rare bird, i.e. An extraodinary or unusual thing. (Juvenal)</p>
<p><b>Ratio decidendi</b> - The reason for the decision</p>
<p><b>Ratio et consilium propriae ducis artes</b> - Reason and deliberation are the proper skills of a general</p>
<p><b>Ratio legis est anima legis</b> - The reason of the law is the soul of the law</p>
<p><b>Re</b> - Concerning</p>
<p><b>Recedite, plebes! Gero rem imperialem!</b> - Stand aside plebians! I am on imperial business!</p>
<p><b>Recto</b> - On the right</p>
<p><b>Redde Caesari quae sunt Caesaris</b> - Render unto Caesar the things that are Caesar's</p>
<p><b>Redivivus</b> - Come back to life</p>
<p><b>Redolet lucernam</b> - [it] smells of the lamp</p>
<p><b>Reductio ad absurdum</b> - Reduction to the absurd. (proving the truth of a proposition by proving the falsity of all its alternatives)</p>
<p><b>Referendum</b> - Something to be referred</p>
<p><b>Regnat non regitur qui nihil nisi quod vult facit</b> - He is a king and not a subject who does only what he wishes. (Syrus)</p>
<p><b>Regnat populus</b> - Let the People rule</p>
<p><b>Relata refero</b> - I tell what I have been told. (Herodotos)</p>
<p><b>Rem tene, verba sequentur</b> - Keep to the subject and the words will follow. (Cato Senior)</p>
<p><b>Repetitio est mater memoriae/ studiorum/</b> - Repetition is the mother of memory/studies</p>
<p><b>Requiescat in pace (RIP)</b> - May he/she rest in peace</p>
<p><b>Rerum concordia discors</b> - The concord of things through discord. (Horace)</p>
<p><b>Res firma mitescere nescit</b> - A firm resolve does not know how to weaken</p>
<p><b>Res gestae</b> - Things done</p>
<p><b>Res in cardine est</b> - The matter is on a door hinge things are balanced on a knife's edge</p>
<p><b>Res inter alios</b> - A matter between others it's not our busines</p>
<p><b>Res ipsa loquitur</b> - The thing speaks for itself</p>
<p><b>Res judicata</b> - Thing already judged upon</p>
<p><b>Res publica</b> - The public thing</p>
<p><b>Res severa est verum gaudium</b> - True joy is a serious thing. (Seneca)</p>
<p><b>Res tantum valet quantum vendi potest</b> - A thing is worth only what someone else will pay for it</p>
<p><b>Respice finem</b> - Look to the end</p>
<p><b>Respice post te, mortalem te esse memento</b> - Look around you, remember that you are mortal. (Tertullianus)</p>
<p><b>Respice, adspice, prospice</b> - Examine the past, examine the present, examine the future (look to the past, the present, the future)</p>
<p><b>Respondeat superior</b> - Let the superior answer (a supervisor must take responsibility for the quality of a subordinate's work)</p>
<p><b>Resurgam</b> - I shall rise again</p>
<p><b>Rex non potest peccare</b> - The king cannot sin</p>
<p><b>Rex regnant sed non gubernat</b> - The king reigns but does not govern</p>
<p><b>Rident stolidi verba latina</b> - Fools laugh at the Latin language. (Ovid)</p>
<p><b>Ridentem dicere verum quid vetat?</b> - What forbids a laughing man from telling the truth? (Horace)</p>
<p><b>Rigor mortis</b> - The rigidity of death</p>
<p><b>Risu inepto res ineptior nulla est</b> - There is nothing more foolish than a foolish laugh. (Catullus)</p>
<p><b>Risus abundat in ore stultorum</b> - Abundant laughs in the mouth of the foolish</p>
<p><b>Roma locuta est. Causa finita est</b> - Rome has spoken. The cause is finished</p>
<p><b>Rosa rubicundior, lilio candidior, omnibus formosior, semper in te glorior</b> - Redder than the rose, whiter than the lilies, fairer than everything, I will always glory in thee</p>
<p><b>Rumores volant. / Rumor volat</b> - Rumors fly. / Rumor flies</p>

   </Layout>
  )
}

export default LatQuotes_r
